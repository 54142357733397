import {
  browserTracingIntegration,
  init,
  setUser,
  SeverityLevel,
  thirdPartyErrorFilterIntegration,
  extraErrorDataIntegration,
} from '@sentry/react';

import { Codeowners } from '../../consts/codeowners';

function isInput(target?: HTMLElement): target is HTMLInputElement {
  return target?.nodeName.toLowerCase() === 'input';
}

function getBreadCrumbMessage(target: HTMLElement | undefined | HTMLInputElement) {
  const textContent = target?.innerText;

  return isInput(target) ? target?.labels?.[0]?.textContent : textContent;
}

const breadCrumbCategories = ['ui.click', 'ui.input'];

export const setupSentry = () => {
  if (window.SENTRY?.enabled) {
    init({
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // Bing blocked
        'UET is not defined',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        'fromLatLngToDivPixel',
        'lineItemType',
        "Can't find variable: analytics",
        // Add browser errors that are not related to our app
        'NS_ERROR_ABORT',
        'NS_ERROR_FILE_NO_DEVICE_SPACE',
        'NS_ERROR_FILE_CORRUPTED',
        // Browser API errors
        'ResizeObserver loop limit exceeded',
        // Network and request errors
        'Request aborted',
        'The network connection was lost',
        'Load failed',
        'ChunkLoadError',
        /Network\s*?Error/,
        /failed with [4,5]\d{2} code/,
        /failed with status code [4,5]\d{2}/,
        'Failed to fetch',
        /**
         * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
         * This can also be filtered by enabling the browser extension inbound filter
         */
        "TypeError: can't access dead object",
        /**
         * React internal error thrown when something outside react modifies the DOM
         * This is usually because of a browser extension or Chrome's built-in translate
         */
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
        'chain is not set up',
        // Ignore timeout errors
        /timeout/i,
        // Ignore ad blocker errors
        'Blocked by Adguard',
        'Resource blocked by content blocker',
        // MS Outlook plugin error
        'Object Not Found Matching Id',
      ],
      allowUrls: [/.*housecallpro.*/],
      debug: HC_ENV_NODE_ENV !== 'production',
      initialScope: {
        tags: { codeowners: Codeowners.CUSTOMER_INTAKE },
      },
      tracePropagationTargets: ['https://pro.housecallpro-staging.com', 'https://pro.housecallpro.com'],
      integrations: (integrations) => [
        ...integrations,
        thirdPartyErrorFilterIntegration({
          filterKeys: ['housecallpro-online-booking-home-owner-flow'],
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
        extraErrorDataIntegration({
          depth: 10,
        }),
        browserTracingIntegration(),
      ],
      release: HC_ENV_REVISION,
      normalizeDepth: 11,
      dsn: 'https://47f06c5f14e3a4bd83da2b94b1362ac0@o284669.ingest.sentry.io/4506337279279104',
      environment: window.SENTRY?.environment,
      beforeBreadcrumb(breadcrumb, hint) {
        try {
          if (breadcrumb.message?.includes('bam.nr-data.net')) {
            return null;
          }

          if (breadcrumb.category && breadCrumbCategories.includes(breadcrumb.category)) {
            const target = hint?.event.target as HTMLElement | undefined;

            return {
              ...breadcrumb,
              level: 'debug' as SeverityLevel,
              message: getBreadCrumbMessage(target) ?? breadcrumb.message,
              data: {
                className: target?.className,
                htmlTag: target?.nodeName,
                id: target?.id,
                dataTestId: target?.getAttribute('data-testid'),
              },
            };
          }

          return breadcrumb;
        } catch (_err) {
          return breadcrumb;
        }
      },
    });
  }
};

export const sentryIdentifyUser = ({ email }: { email: string }) => {
  setUser({
    email,
  });
};
